<template>
  <div class="d-flex align-center">
    <component :is="sComponent" v-bind="$attrs" v-on="$listeners">
      {{ sValue }}
    </component>

    <div
      v-if="bShowTaxLabel"
      class="ml-4 text-caption grey--text text--darken-1"
      v-text="$t(sTaxLabel)"
    />
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import TaxTypeCacheMixin from "@/modules/taxtypes/mixins/TaxTypeCacheMixin";
import type { Currency, CurrencyData } from "@planetadeleste/vue-mc-shopaholic";
import { TaxTypeCollection } from "@planetadeleste/vue-mc-gw";
import { currencyFormat, round } from "@/plugins/helpers";
import { AppModule } from "@/store/app";
import { VChip } from "vuetify/lib";

@Component({
  components: { VChip },
})
export default class PriceViewer extends Mixins(TaxTypeCacheMixin) {
  @Prop([String, Number]) readonly value!: number;
  @Prop(String) readonly currencyCode!: string;
  @Prop(Number) readonly currencyId!: number;
  @Prop(Object) readonly currency!: CurrencyData | Currency;
  @Prop(Boolean) readonly chip!: boolean;
  @Prop(Boolean) readonly withoutTax!: boolean;
  @Prop(Boolean) readonly showTaxLabel!: boolean;
  @Prop({ type: String, default: "span" }) readonly tag!: string;

  obTaxTypeCollection: TaxTypeCollection = new TaxTypeCollection();

  get sComponent() {
    return this.chip ? "v-chip" : this.tag;
  }

  get fValue() {
    return round(this.value);
  }

  get sValue() {
    if (!this.value) {
      return "0";
    }

    let sCode = this.offer ? this.offer.currency_code : this.currencyCode;

    if (!sCode) {
      if (this.currency) {
        sCode = this.currency.code;
      } else if (this.currencyId) {
        const obCurrency = AppModule.currencies.find({ id: this.currencyId });
        if (obCurrency) {
          sCode = obCurrency.code;
        }
      }
    }

    return sCode ? currencyFormat(this.fValue, sCode) : this.fValue;
  }

  get bShowTaxLabel() {
    if (!this.showTaxLabel || !this.value) {
      return false;
    }

    return this.showTaxLabel;
  }

  get sTaxLabel() {
    let sLabel = this.withoutTax ? "without" : "with";

    if (this.obTaxType && !this.taxPercent) {
      sLabel = "no";
    }

    return `label.${sLabel}.tax`;
  }
}
</script>
