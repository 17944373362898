<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        disable-sort
        hide-default-footer
      >
        <template #[`item.sort_order`]="{ item }">
          <span v-if="item.sort_order < 99" v-text="item.sort_order" />
        </template>

        <template #[`item.code`]="{ item, index }">
          <account-code-select
            v-if="item.sort_order < 99"
            :currency-id="currencyId"
            :value="item.code"
            hide-label
            @change="onSetFieldValue('code', $event, index)"
          />
        </template>

        <template #[`item.debit`]="{ item, index }">
          <price-viewer
            v-if="item.sort_order >= 99"
            :currency="currency"
            :value="item.debit"
            chip
          />
          <price-field
            v-else
            :disabled="!!item.credit && !item.debit"
            :value="item.debit"
            @input:debounce="onSetFieldValue('debit', $event, index)"
          />
        </template>

        <template #[`item.credit`]="{ item, index }">
          <price-viewer
            v-if="item.sort_order >= 99"
            :currency="currency"
            :value="item.credit"
            chip
          />
          <price-field
            v-else
            :disabled="!item.credit && !!item.debit"
            :value="item.credit"
            @input:debounce="onSetFieldValue('credit', $event, index)"
          />
        </template>

        <template #[`item.valid`]="{ item }">
          <active-icon :active="item.valid" with-error />
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Mixins, VModel } from "vue-property-decorator";
import type { AccountingEntryInvoicePositionData } from "@planetadeleste/vue-mc-gw";
import { AccountingEntryInvoice } from "@planetadeleste/vue-mc-gw";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import DataTableMixin from "@/mixins/DataTableMixin";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import AccountCodeSelect from "@/modules/accounts/components/AccountCodeSelect.vue";
import PriceField from "@/components/form/fields/PriceField.vue";
import useMath from "@/composables/math";
import PriceViewer from "@/components/common/PriceViewer.vue";
import type { CurrencyData } from "@planetadeleste/vue-mc-shopaholic";
import { filter, get, hasIn, invoke, isObject, set } from "lodash";
import { round } from "@/plugins/helpers";

const { math, sumBy } = useMath();

@Component({
  components: { PriceViewer, PriceField, AccountCodeSelect, ActiveIcon },
})
export default class AccountingentryinvoicesSettings extends Mixins(
  DataTableMixin
) {
  @VModel({ type: Object, default: () => new AccountingEntryInvoice() })
  obAccountingEntryInvoice!: AccountingEntryInvoice;

  get positions() {
    return this.obAccountingEntryInvoice.get("positions", []);
  }

  get items() {
    const arPositions = [...this.positions];
    const arValidPositions = filter(arPositions, (obItem) => obItem.valid);
    const obPosition: Partial<AccountingEntryInvoicePositionData> = {
      sort_order: 99,
      debit: this.debit,
      credit: this.credit,
      valid:
        math.subtract(this.credit, this.debit) === 0 &&
        arValidPositions.length === arPositions.length,
      name: this.$t("accounting.total") as string,
    };

    set(obPosition, "css", "grey lighten-1");
    arPositions.push(obPosition);

    return arPositions;
  }

  get debit(): number {
    return round(sumBy(this.positions, "debit"));
  }

  get credit(): number {
    return round(sumBy(this.positions, "credit"));
  }

  get currency(): CurrencyData | undefined {
    return this.obAccountingEntryInvoice.get("currency");
  }

  get currencyId() {
    return this.obAccountingEntryInvoice.get("currency_id", 0);
  }

  onSetFieldValue(sField: string, sValue: any, sIndex: number) {
    const obPosition = get(this.positions, sIndex) as
      | AccountingEntryInvoicePositionData
      | undefined;

    if (!obPosition) {
      return;
    }

    if (isObject(sValue)) {
      sValue = hasIn(sValue, "get")
        ? invoke(sValue, "get", sField)
        : get(sValue, sField);
    }

    set(obPosition, sField, sValue);

    if ((obPosition.debit || obPosition.credit) && obPosition.code) {
      set(obPosition, "valid", true);
    }

    const arPositions = [...this.positions];
    arPositions.splice(sIndex, 1, obPosition);
    this.obAccountingEntryInvoice.set("positions", arPositions);
  }

  mounted() {
    const arHeaders: DataTableHeader[] = [
      { text: "#", value: "sort_order", translated: true },
      { text: "account.code", value: "code", width: 200 },
      { text: "accounting.name", value: "name" },
      { text: "accounting.debit", value: "debit", align: "end" },
      { text: "accounting.credit", value: "credit", align: "end" },
      { text: "accounting.valid", value: "valid", align: "center" },
    ];
    this.setDTHeaders(arHeaders);
  }
}
</script>
